export const MONTHLY_REVENUE_SUMMARIES = `
  query($year: Int!, $month: Int!) {
    monthlyRevenueSummaries(year: $year, month: $month) {
      facility {
        name
      } 
      revenues {
        title
        amount
      }
    }
  }
`
