<template>
  <base-form-report>
    <template v-slot:default>
      <div class="mt-5 mb-2">
        <!-- Date picker -->
        <v-menu
          v-model="menuDatePicker"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on }">
            <div>
              <v-icon>mdi-calendar-month</v-icon>
              <v-btn
                v-model="datePicker"
                v-on="on"
                small
                class="btn--date mr-5"
                >{{ formatMonth(datePicker) }}</v-btn
              >
            </div>
          </template>
          <v-date-picker
            v-model="datePicker"
            :first-day-of-week="0"
            :locale="$i18n.locale"
            scrollable
            type="month"
            @input="menuDatePicker = false"
            @change="fetchData"
          ></v-date-picker>
        </v-menu>
        <!--  -->
      </div>

      <!-- List table (facility) -->
      <template v-if="monthlyRevenueSummaries.length > 0">
              <div class="mb-6" v-for="(item,index) in monthlyRevenueSummaries" :key="index">
        <h3 class="facility-name font-weight-bold mb-3">{{ item.facility.name }}</h3>
        <v-col cols="12" lg="6" class="elevation-1 pa-0">
          <v-simple-table class="table-custom" dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>
                    {{ $t("common.item") }}
                  </th>
                  <th>
                    {{ $t("common.amountOfMoney") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(el, index) in item.revenues" :key="index">
                  <td>{{ el.title}}</td>
                  <td>
                    <tvos-thousands class="tvos-jpy" :value="el.amount" prefix="¥" />
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </div>
      </template>
      <!-- ----------------- -->
    </template>
  </base-form-report>
</template>

<script>
import BaseFormReport from '../../../components/FormReport/BaseFormReport'
import { formatMonth, handlError, getCopyErrorTextView } from '@/constants/functions'
import { MONTHLY_REVENUE_SUMMARIES } from '@/api/graphql/formReport/monthly-revenue-summaries'
import { mapMutations } from 'vuex'
import gql from 'graphql-tag'

export default {
  name: 'ForIncomeReport',
  data () {
    return {
      menuDatePicker: false,
      datePicker: new Date().toISOString().substr(0, 10),
      monthlyRevenueSummaries: []
    }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    getCopyErrorTextView,
    ...mapMutations(['setCopyErrorText', 'setLoadingOverlayShow', 'setLoadingOverlayHide']),
    formatMonth,
    async fetchData () {
      const year = new Date(this.datePicker).getFullYear()
      const month = new Date(this.datePicker).getMonth() + 1
      this.setLoadingOverlayShow()
      const variables = {
        year,
        month
      }
      await this.$apollo
        .query({
          query: gql`${MONTHLY_REVENUE_SUMMARIES}`,
          variables: variables,
          fetchPolicy: 'no-cache'
        })
        .then(data => {
          this.monthlyRevenueSummaries = data.data.monthlyRevenueSummaries
          this.setLoadingOverlayHide()
        })
        .catch(error => {
          // console.error(error)
          this.setCopyErrorText(this.getCopyErrorTextView(MONTHLY_REVENUE_SUMMARIES, variables, error.graphQLErrors))
          this.setLoadingOverlayHide()
          handlError(this.$store.commit, error.graphQLErrors)
        })
    }
  },
  components: {
    BaseFormReport
  }
}
</script>

<style scoped lang="scss">
  ::v-deep {
     .v-data-table {
        tbody tr td {
        color: #757575 !important
      }
      .v-data-table__wrapper table  thead  tr  th {
        color: #757575 !important;
      }
    }
  }
  .facility-name {
    font-size: 20px;
  }
</style>
